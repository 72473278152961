import { computed } from 'vue'
import { useActiveFacility } from '~/composables/use-active-facility'

export function isSfrc() {
    const activeFacility = useActiveFacility()
    return activeFacility.value.organization.code.startsWith('sfrc')
}

export function isApp() {
    const activeFacility = useActiveFacility()
    console.log(activeFacility.value.organization.code)
    return computed(() => {
        return activeFacility.value.organization.code.startsWith(
            'portal.redpointhq.com'
        )
    })
}

export function isDemo() {
    const activeFacility = useActiveFacility()
    return computed(() =>
        activeFacility.value.organization.code.startsWith('demoportal.rphq.com')
    )
}

export function isEdgeworksProd() {
    const activeFacility = useActiveFacility()
    return computed(() =>
        activeFacility.value.organization.code.startsWith('edgeworks')
    )
}

export function useOrgShortTitle() {
    const activeFacility = useActiveFacility()

    return computed(() => activeFacility.value.organization.shortName)
}
